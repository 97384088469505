export const getEnvironment = () => {
  return {
    apiUrl: "https://aufgaben.falkpost.de/liste/api.php",
    redirectUrl: "https://aufgaben.falkpost.de/liste/",
    version: "liste",
    language: {
      sublist: "1. ",
      task_box1: "Aufgabe",
      task_box2: "Fälligkeit",
      task_box3: "Information",
      icon1: "clock",
      icon2: "info",
    },
  };
};
